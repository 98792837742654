import AudioSync from 'components/AudioSync';
import Box from 'components/Box';
import Stack from 'components/Stack';
import Text from 'components/Text';
import Tooltip from 'components/Tooltip';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isSyncing: boolean;
  duration: number;
}

const AudioSyncEnabledScreen: FC<Props> = ({ isSyncing, duration }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing="xlarge" align="center">
      <Box paddingX="gutter">
        <Text
          component="h1"
          color="secondary"
          fontWeight="bold"
          fontSize="xlarge"
          textAlign="center"
        >
          {t('The app will now try to synchronize with your TV!')}
        </Text>
      </Box>

      <Stack spacing="small" align="center" paddingX="large">
        <AudioSync syncing={isSyncing} duration={duration} />

        <Tooltip>
          <Box padding="small">
            <Text fontSize="small" color="black" textAlign="center">
              {t('Point your device towards your TV to start synchronization.')}
            </Text>
            <Text fontSize="small" color="black" textAlign="center">
              {t('Is your microphone activated?')}
            </Text>
          </Box>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

export default AudioSyncEnabledScreen;
