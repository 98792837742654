import { trackPageView } from 'actions/analyticsActions';
import { requestMicrophoneAccess, startAudioSync, stopAudioSync } from 'actions/bridgeActions';
import { playWithAverageOffset } from 'actions/episodeScheduleActions';
import Box from 'components/Box';
import FlatButton from 'components/FlatButton';
import MenuToggle from 'components/MenuToggle';
import TopBar from 'components/TopBar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAudioSync } from 'selectors/audioSyncSelectors';
import { getEpisode } from 'selectors/episodeSelectors';
import { getNativeEnv } from 'selectors/nativeSelectors';
import AudioSyncDisabledScreen from './AudioSyncDisabledScreen';
import AudioSyncEnabledScreen from './AudioSyncEnabledScreen';
import AudioSyncTimeoutScreen from './AudioSyncTimeoutScreen';

const AudioSyncScreen = () => {
  const dispatch = useDispatch();
  const maxSyncDuration = window.emconfig.settings.audioSyncTimeout;
  const { isSyncing, microphoneEnabled, microphoneAccessDisabled } = useSelector(getAudioSync);
  const nativeEnv = useSelector(getNativeEnv);

  const episode = useSelector(getEpisode);
  const [tries, setTry] = useState(0);
  const [syncTimedOut, setSyncTimedOut] = useState(false);

  const hideScreen = nativeEnv === 'native' && !microphoneEnabled && !microphoneAccessDisabled;

  useEffect(() => {
    if (microphoneAccessDisabled) {
      return;
    }

    dispatch(requestMicrophoneAccess());
  }, [microphoneAccessDisabled]);

  useEffect(() => {
    if (!microphoneEnabled) {
      return;
    }

    const timer = setTimeout(() => {
      dispatch(stopAudioSync());
      setSyncTimedOut(true);
      dispatch(trackPageView('AudioSync.TIME_OUT'));
    }, maxSyncDuration);

    dispatch(startAudioSync());
    dispatch(trackPageView('AudioSync.START'));

    return () => {
      clearTimeout(timer);
      dispatch(stopAudioSync());
    };
  }, [tries, microphoneEnabled]);

  const playWithAverage = () => {
    if (!episode) {
      return;
    }

    dispatch(trackPageView('AudioSync.PLAY_WITH_AVERAGE'));
    dispatch(playWithAverageOffset(episode.episodeCode));
  };

  const retry = () => {
    setSyncTimedOut(false);
    setTry(tries + 1);
  };

  return (
    <>
      <TopBar logo left={<MenuToggle />} />

      {!hideScreen && (
        <Box padding="gutter">
          {microphoneEnabled ? (
            syncTimedOut ? (
              <AudioSyncTimeoutScreen
                retry={retry}
                tries={tries}
                playWithAverage={playWithAverage}
              />
            ) : (
              <AudioSyncEnabledScreen
                isSyncing={isSyncing && !syncTimedOut}
                duration={maxSyncDuration}
              />
            )
          ) : (
            <AudioSyncDisabledScreen playWithAverage={playWithAverage} />
          )}
        </Box>
      )}

      {__DEV__ && <FlatButton onClick={playWithAverage}>{'[DEV] Skip Sync'}</FlatButton>}
    </>
  );
};

export default AudioSyncScreen;
